<template>
    <div style="height: 100%">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item label="商品分类">
                                <el-cascader v-model="searchForm.categoryId" :options="CategoryData"
                                    :props="{ checkStrictly: true, value: 'id', label: 'name', children: 'children' }"
                                    size="small" clearable @change="search"></el-cascader>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="商品名称"
                                    @keyup.enter.native="search" clearable></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                <el-button type="primary" size="small" @click="AddCommodity" plain>发布</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length>0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>

                </div>
                <el-table :data="tableData" height="calc(100% - 90px)" stripe style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="name" label="商品名称" width="120px" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="category.name" label="分类"></el-table-column>
                    <el-table-column prop="isHot" label="热销商品">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.isHot" active-color="#E6A23C" inactive-color="#999999"
                                @change="changeHot(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="isNewProducts" label="新品" align="center">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.isNewProducts" active-color="#089BAB" inactive-color="#999999"
                                @change="changeNew(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="isLimit" label="限购" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="!scope.row.isLimit" type="warning" size="small">否</el-tag>
                            <el-tag v-if="scope.row.isLimit" type="success" size="small">是</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isChargeback" label="退单" align="center">
                        <template slot-scope="scope">
                            <el-tag v-if="!scope.row.isChargeback" type="warning" size="small">不可退</el-tag>
                            <el-tag v-if="scope.row.isChargeback" type="success" size="small">可退单</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stockNum" label="库存" align="center"></el-table-column>
                    <el-table-column prop="salePrice" label="销售总金额（元）" align="center">
                    </el-table-column>
                    <el-table-column prop="state" label="展示中" align="center">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.state" :active-value="1" :inactive-value="0"
                                active-color="#13ce66" inactive-color="#999999" @change="changeStatus(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="isCommended" label="每日推荐" align="center">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.isCommended" active-color="#F56C6C" inactive-color="#999999"
                                @change="changeCommended(scope.row)">
                            </el-switch>
                        </template>
                    </el-table-column> -->
                    <el-table-column fixed="right" label="操作" width="250" align="center">
                        <template slot-scope="scope">
                            <span class="info optionBtn" @click="toEdit(scope.row)" v-if="scope.row.state==0">编辑</span>
                            <span class="warning optionBtn" @click="showInfo(scope.row)">详情</span>
                            <span class="success optionBtn" @click="SetSpecifications(scope.row)" v-if="scope.row.state==0">价格/库存</span>
                            <span class="danger optionBtn" @click="remove(scope.row)" v-if="scope.row.state==0">删除</span>
                            <!-- <el-button type="primary" size="mini" @click="addSale(scope.row)" plain>限时抢购</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="CommodityTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <el-dialog title="商品规格管理" :visible.sync="dialogVisible2" width="1200px">
            <el-form :model="commoditySpecifications" :rules="rules" ref="commoditySpecifications">
                <el-table :data="commodityForm.commoditySpecifications" border style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column prop="name" align="center" label="规格名称">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="name">
                                <el-input v-if="scope.row.edit" v-model="scope.row.name" size="small"
                                    placeholder="规格名称">
                                </el-input>
                                <span v-else>{{ scope.row.name }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="guidancePrice" align="center" label="指导价(元)">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="guidancePrice">
                                <el-input v-if="scope.row.edit" v-model="scope.row.guidancePrice" size="small"
                                    placeholder="指导价(元)">
                                </el-input>
                                <span v-else>{{ scope.row.guidancePrice }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="vip1Price" align="center" label="VIP1(元)">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="vip1Price">
                                <el-input v-if="scope.row.edit" v-model="scope.row.vip1Price" size="small"
                                    placeholder="VIP1(元)"></el-input>
                                <span v-else>{{ scope.row.vip1Price }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="vip2Price" align="center" label="VIP2(元)">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="vip2Price">
                                <el-input v-if="scope.row.edit" v-model="scope.row.vip2Price" size="small"
                                    placeholder="VIP2(元)"></el-input>
                                <span v-else>{{ scope.row.vip2Price }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ordinaryPrice" align="center" label="非会员价(元)">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="ordinaryPrice">
                                <el-input v-if="scope.row.edit" v-model="scope.row.ordinaryPrice" size="small"
                                    placeholder="非会员价(元)"></el-input>
                                <span v-else>{{ scope.row.ordinaryPrice }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="integral" align="center" label="积分">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="integral">
                                <el-input v-if="scope.row.edit" v-model="scope.row.integral" size="small"
                                    placeholder="积分">
                                </el-input>
                                <span v-else>{{ scope.row.integral }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stockNum" align="center" label="库存数量">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="stockNum">
                                <el-input v-if="scope.row.edit" v-model="scope.row.stockNum" size="small"
                                    placeholder="库存数量">
                                </el-input>
                                <span v-else>{{ scope.row.stockNum }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rank" align="center" label="排序">
                        <template slot-scope="scope">
                            <el-form-item label="" prop="rank">
                                <el-input v-if="scope.row.edit" v-model="scope.row.rank" size="small" placeholder="排序">
                                </el-input>
                                <span v-else>{{ scope.row.rank }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" align="center">
                        <template slot-scope="scope">
                            <el-button @click="confirmData(scope.row)" v-if="scope.row.edit" type="success" size="mini"
                                plain>
                                <i class="el-icon-check" aria-hidden="true"></i>
                            </el-button>
                            <template v-else>
                                <el-button type="warning" size="mini" plain @click="editData(scope.row)">
                                    <i class="el-icon-edit" aria-hidden="true"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="saveSpecifications('commoditySpecifications')">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="Image.dialogVisible">
            <img width="100%" :src="Image.dialogImageUrl" alt="">
        </el-dialog>
        <el-dialog title="积分商品详情" :visible.sync="dialogVisible" width="900px">
            <el-form>
                <el-form-item label="商品名称" :label-width="formLabelWidth">
                    <el-input class="w-18" v-model="commodityForm.name" disabled></el-input>
                </el-form-item>
                <el-form-item label="商品封面" :label-width="formLabelWidth">
                    <el-upload :action="FileUploadUrl" class="img-div" list-type="picture-card" :limit="5" multiple
                        :on-preview="handleImagePreview" :file-list="ImageContentList" disabled>
                    </el-upload>
                </el-form-item>
                <el-form-item label="商品分类" prop="categoryId" :label-width="formLabelWidth">
                    <el-cascader v-model="commodityForm.categoryId" :options="CategoryData"
                        :props="{ value: 'id', label: 'name', children: 'children', emitPath: false }" disabled>
                    </el-cascader>
                </el-form-item>
                <el-form-item v-for="(item, index) in commodityForm.commodityParameters" :label="item.key"
                    :key="item.key" :prop="'commodityParameters.' + index + '.value'" :label-width="formLabelWidth">
                    <el-input class="w-18" v-model="item.value" disabled></el-input>
                </el-form-item>
                <el-form-item label="商品规格管理" :label-width="formLabelWidth">
                    <el-table class="tab" :data="commodityForm.commoditySpecifications" border style="width: 100%">
                        <el-table-column prop="name" align="center" label="规格名称">
                        </el-table-column>
                        <!-- <el-table-column prop="guidancePrice" align="guidancePrice" label="指导价(元)">
                        </el-table-column>
                        <el-table-column prop="vip1Price" align="center" label="VIP1(元)">
                        </el-table-column>
                        <el-table-column prop="vip2Price" align="center" label="VIP2(元)">
                        </el-table-column> -->
                        <el-table-column prop="ordinaryPrice" align="center" label="价格(元)">
                        </el-table-column>
                        <el-table-column prop="integral" align="center" label="积分">
                        </el-table-column>
                        <el-table-column prop="stockNum" align="center" label="库存数量">
                        </el-table-column>
                        <el-table-column prop="rank" align="center" label="排序(小数靠前)">
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="是否限购" :label-width="formLabelWidth">
                    <el-radio-group v-model="commodityForm.limitType">
                        <el-radio :label="0" v-if="commodityForm.limitType==0">不限购</el-radio>
                        <el-radio :label="1" v-if="commodityForm.limitType == 1" style="margin: 0 10px 0px 0px;">
                            周期限购：每个会员，整个活动周期限购 <el-input type="number" style="width: 70px"
                                v-model="commodityForm.limitNum" size="small" v-if="commodityForm.limitType == 1"
                                disabled>
                            </el-input> 份</el-radio>
                        <el-radio :label="2" v-if="commodityForm.limitType == 2" style="margin: 0 10px 10px 0px;">
                            每日限购：每个会员，每日限购 <el-input type="number" style="width: 70px" v-model="commodityForm.limitNum"
                                size="small" v-if="commodityForm.limitType == 2" disabled>
                            </el-input> 份</el-radio>
                        <el-radio :label="3" v-if="commodityForm.limitType == 3">每月限购：每个会员，每月限购 <el-input type="number"
                                style="width: 70px" v-model="commodityForm.limitNum" size="small"
                                v-if="commodityForm.limitType == 3" disabled></el-input> 份
                            <span style="color: #ff0000">*适用于长期活动</span>
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="支付等待" prop="cancellationOrder" :label-width="formLabelWidth">
                    <div class="isShare-box">
                        <div>订单 <el-input type="number" v-model="commodityForm.cancellationOrder" size="small" disabled>
                            </el-input>
                            分钟未支付，自动取消订单</div>
                    </div>
                </el-form-item>
                <el-form-item label="" prop="completionOrder" :label-width="formLabelWidth">
                    <div class="isShare-box">
                        <div>订单 <el-input type="number" v-model="commodityForm.completionOrder" size="small" disabled>
                            </el-input>
                            天，自动确认收货</div>
                    </div>
                </el-form-item>
                <el-form-item label="是否需要发货" :label-width="formLabelWidth">
                    <el-switch v-model="commodityForm.isSend" active-color="#13ce66" inactive-color="#999999" disabled>
                    </el-switch>
                </el-form-item>
                <el-form-item label="快递费用" v-if="commodityForm.isSend == true" :label-width="formLabelWidth">
                    <el-radio-group v-model="commodityForm.freightType">
                        <el-radio :label="2" v-if="commodityForm.freightType==2" style="margin-top: 15px;">免运费
                        </el-radio>
                        <el-radio :label="1" v-if="commodityForm.freightType==1" style="margin: 20px 10px 20px 0;">固定运费
                        </el-radio>
                        <el-input type="number" style="width: 70px" v-model="commodityForm.freightMoney" disabled
                            size="small">
                        </el-input> 元
                        <el-radio :label="3" v-if="commodityForm.freightType==3">运费模板</el-radio>
                        <el-select v-if="commodityForm.freightType==3" v-model="commodityForm.freightTemplateId"
                            disabled size="small" placeholder="请选择">
                            <el-option v-for="item in freightTemplate" :label="item.templateName" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否开具发票" :label-width="formLabelWidth">
                    <el-switch v-model="commodityForm.isInvoice" active-color="#13ce66" inactive-color="#999999"
                        disabled>
                    </el-switch>
                </el-form-item>

                <el-form-item label="是否可退单" :label-width="formLabelWidth">
                    <el-radio-group v-model="commodityForm.chargebackType">
                        <el-radio :label="1" v-if="commodityForm.chargebackType==1">不可退单</el-radio>
                        <el-radio :label="2" v-if="commodityForm.chargebackType==2">随时可退</el-radio>
                        <el-radio :label="3" v-if="commodityForm.chargebackType==3">支付完成24小时可退</el-radio>
                    </el-radio-group>
                    <div class="isShare-box"
                        v-if="commodityForm.chargebackType == '2' || commodityForm.chargebackType == '3'">
                        <div>退单时，需支付手续费 <el-input v-model="commodityForm.chargebackMoney" size="small" type="number"
                                disabled>
                            </el-input> 元，输入0元，为退单无需手续费</div>
                    </div>
                </el-form-item>
                <el-form-item label="购买须知" prop="instructions" :label-width="formLabelWidth">
                    <i class="v-html" v-html="commodityForm.instructions"></i>
                </el-form-item>
                <el-form-item label="商品介绍" prop="introduce" :label-width="formLabelWidth">
                    <i class="v-html" v-html="commodityForm.introduce"></i>
                </el-form-item>
                <el-form-item label="售后服务" prop="customerService" :label-width="formLabelWidth">
                    <i class="v-html" v-html="commodityForm.customerService"></i>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { Commodity } from "../../../components/HospitalDomain/ShopMall/commodity";
import { CommodityCategory } from "../../../components/HospitalDomain/ShopMall/CommodityCategory";
import bread from "../../../components/common/breadcrumb";

export default {
    name: "commodity",
    components: {
        bread
    },
    data() {
        var commodity = new Commodity(this.TokenClient, this.Services.Shopping);
        var commodityCategory = new CommodityCategory(this.TokenClient, this.Services.Shopping)
        return {
            CommodityDomain: commodity,
            CommodityCategory: commodityCategory,
            tableData: [],
            CategoryData: [],
            searchForm: {
                state: '0',
                categoryId: '',
                keyWord: '',
            },
            CategoryId: 0,
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 0,
            CommodityTotal: 1,
            formLabelWidth: '100px',
            CommodityDialog: false,
            CommodityTitle: '新增积分商品',
            steps: 1,
            commodityForm: {
                commoditySpecifications: [],
            },
            editIndex: 0,
            commoditySpecifications: {
                name: '',
                // guidancePrice: null,
                // vip1Price: null,
                // vip2Price: null,
                // membershipPrice: 0,
                ordinaryPrice: null,
                isIntegral: true,
                integral: null,
                stockNum: null,
                rank: 0
            },
            dialogVisible: false,
            dialogVisible2: false,
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                // guidancePrice: [
                //     { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                //     { required: true, message: '请输入指导价', trigger: 'blur' },
                // ],
                // vip1Price: [
                //     { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                //     { required: true, message: '请输入正确的金额', trigger: 'blur' },
                // ],
                // vip2Price: [
                //     { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                //     { required: true, message: '请输入正确的金额', trigger: 'blur' },
                // ],
                // membershipPrice: [
                //     { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                //     { required: true, message: '请输入会员价', trigger: 'blur' },
                // ],
                ordinaryPrice: [
                    { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
                    { required: true, message: '请输入非会员价', trigger: 'blur' },
                ],
                integral: [
                    { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "blur" },
                    { required: true, message: '请输入积分', trigger: 'blur' },
                ],
                stockNum: [
                    { pattern: /^[1-9]\d*$/, message: "请输入正整数", trigger: "blur" },
                    { required: true, message: '请输入库存', trigger: 'blur' },
                ],
                rank: [
                    { pattern: /^[0-9]\d*$/, message: "请输入整数", trigger: "blur" },
                    { required: true, message: '请输入排序', trigger: 'blur' },
                ],
            },
            json_fields: {
                "商品名称": 'name',
                "分类": 'category.name',
                "热销商品": {
                    filed: 'isHot',
                    callback: val => {
                        return val.isHot ? '是' : '否'
                    }
                },
                "新品": {
                    filed: 'isNewProducts',
                    callback: val => {
                        return val.isNewProducts ? '是' : '否'
                    }
                },
                "限购": {
                    filed: 'isLimit',
                    callback: val => {
                        return val.isLimit ? '是' : '否'
                    }
                },
                "退单": {
                    filed: 'isChargeback',
                    callback: val => {
                        return val.isChargeback ? '可退单' : '不可退单'
                    }
                },
                "库存": 'stockNum',
                "销售总金额": 'salePrice',
                "展示中": {
                    filed: 'state',
                    callback: val => {
                        return val.state ? '可退单' : '不可退单'
                    }
                },
                "每日推荐": {
                    filed: 'isCommended',
                    callback: val => {
                        return val.isCommended ? '可退单' : '不可退单'
                    }
                },
            },
            title: '积分商品数据',
            DataCount: 0,
            commodifyCategory: [],
            freightTemplate: [],
            Image: {
                //单击图片上传控件中的图片时，放大显示
                dialogVisible: false,
                dialogImageUrl: '',
            },
            ImageContent: [],//图片列表
            ImageContentList: [],//图片上传控件加载的数据
            FileUploadUrl: this.Services.Shopping + '/api/Upload',
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            isShare: false
        }
    },
    mounted() {
        this.GetCategory();
        this.LoadCommoditys();
        this.LoadFreightTemplate();
        this.getShopSetting()
    },
    methods: {
        LoadFreightTemplate() {
            var _this = this;
            _this.CommodityDomain.FreightTemplate(_this.organizationId,
                function (data) {
                    _this.freightTemplate = data.data;
                },
                function (err) {
                    console.log(err);
                });
        },
        getShopSetting() {
            var _this = this;
            _this.CommodityDomain.GetShopSetting(
                function (data) {
                    _this.isShare = data.data.isShare
                },
                function (err) { console.log(err); });
        },
        CommodityStatus(row) {
            if (row.state == '0') {
                return '<el-tag type="warning">待上架</el-tag>'
            } else if (row.state == '1') {
                return '<el-tag type="success">展示中</el-tag>';
            }
        },
        GetCategory() {
            var _this = this;
            _this.CommodityCategory.Categories(
                function (data) {
                    // _this.CategoryData = data.data;
                    if (data.data) {
                        data.data.forEach(element => {
                            if (element.name != "处方药") {
                                _this.CategoryData.push(element)
                            }
                        });
                    }
                },
                function (err) {
                    console.log(err);
                });
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                var item = _this.searchForm;
                var categoryId = item.categoryId[item.categoryId.length - 1]
                _this.CommodityDomain.IntegralCommodityList(item.keyWord, categoryId, 1, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        resolve('');
                        console.log(err);
                    });
            })

        },
        LoadCommoditys() {
            var _this = this;
            _this.tableData = [];
            var item = _this.searchForm;
            var categoryId = item.categoryId[item.categoryId.length - 1]
            _this.CommodityDomain.IntegralCommodityList(item.keyWord, categoryId, _this.PageIndex, null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.CommodityTotal = data.data.dataTotal;
                    if (!item.keyWord) {
                        _this.DataCount = data.data.dataTotal;
                    }
                },
                function (err) {
                    console.log(err);
                });
        },
        search() {
            this.PageIndex = 1;
            this.LoadCommoditys();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.LoadCommoditys();
        },
        AddCommodity() {
            this.$router.push('/ShopMall/addIntegralCommodity');
        },
        changeStatus(item) {
            var _this = this;
            _this.CommodityDomain.UporDown(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        changeCommended(item) {
            var _this = this;
            _this.CommodityDomain.IsCommended(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        changeHot(item) {
            var _this = this;
            _this.CommodityDomain.IsHot(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        changeNew(item) {
            var _this = this;
            _this.CommodityDomain.IsNew(item.id,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    _this.LoadCommoditys();
                },
                function (error) {
                    console.log(error);
                });
        },
        toEdit(item) {
            var _this = this;
            _this.$router.push(
                {
                    path: '/ShopMall/addIntegralCommodity',
                    // path: '/ShopMall/editIntegralCommodity',
                    query: { id: item.id }
                }
            );
        },
        addSale(item, isEdit) {
            console.log(item.id)
            var _this = this;
            _this.$router.push(
                {
                    path: '/ShopMall/addSaleCommodity',
                    query: { id: item.id, edit: isEdit }
                }
            );
        },
        handleImagePreview(file) {
            this.Image.dialogImageUrl = file.url;
            this.Image.dialogVisible = true;
        },
        showInfo(item) {
            var _this = this
            _this.commodityForm.coverPicture = []
            _this.ImageContentList = []
            _this.CommodityDomain.Detail(item.id,
                function (data) {
                    _this.commodityForm = {
                        id: data.data.id,
                        type: 1,
                        attribute: 2,
                        name: data.data.name,
                        categoryId: data.data.categoryId,
                        commodityParameters: data.data.commodityParameters,
                        isHot: data.data.isHot,
                        isCommended: data.data.isCommended,
                        isShare: data.data.isShare,
                        memberShare: data.data.memberShare,
                        ordinaryShare: data.data.ordinaryShare,
                        commoditySpecifications: data.data.commoditySpecifications,
                        commonFirst: data.data.commonFirst,
                        commonSecond: data.data.commonSecond,
                        vip1First: data.data.vip1First,
                        vip1Second: data.data.vip1Second,
                        vip2First: data.data.vip2First,
                        vip2Second: data.data.vip2Second,
                        isPublicShare: data.data.isPublicShare,
                        publicShareMoney: data.data.publicShareMoney,
                        limitType: data.data.limitType,
                        limitNum: data.data.limitNum,
                        cancellationOrder: data.data.cancellationOrder,
                        completionOrder: data.data.completionOrder,
                        isSend: data.data.isSend,
                        freightType: data.data.freightType,
                        freightMoney: data.data.freightMoney,
                        freightTemplateId: data.data.freightTemplateId,
                        isInvoice: data.data.isInvoice,
                        chargebackType: data.data.chargebackType,
                        chargebackMoney: data.data.chargebackMoney,
                        coverPicture: [],
                        introduce: data.data.introduce.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                        instructions: data.data.instructions.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                        customerService: data.data.customerService.replace(/<img/gi, '<img style="max-width:100%;height:auto" ').replace(/<video/gi, '<video style="max-width:100%;height:auto" '),
                    }
                    let dataArry = JSON.parse(JSON.stringify(_this.commodityForm.commoditySpecifications ? _this.commodityForm.commoditySpecifications : []));
                    dataArry.forEach((element) => {
                        element["edit"] = true;
                    });
                    _this.commodityForm.commoditySpecifications = dataArry;
                    _this.ImageContentList = []
                    _this.commodityForm.coverPicture = []
                    data.data.coverPicture.forEach(element => {
                        let obj = new Object();
                        obj.url = _this.Services.Shopping + element.filePath;
                        _this.ImageContentList.push(obj);
                        let imgMap = {
                            fileName: element.fileName,
                            filePath: element.filePath
                        }
                        _this.commodityForm.coverPicture.push(imgMap)
                    });
                    _this.dialogVisible = true
                }, function (err) { console.log(err); });
        },
        SetSpecifications(item) {
            var _this = this;
            _this.CommodityDomain.Detail(item.id,
                function (data) {
                    _this.commodityForm.commoditySpecifications = data.data.commoditySpecifications;
                    let result = JSON.parse(JSON.stringify(_this.commodityForm.commoditySpecifications ? _this.commodityForm.commoditySpecifications : []));
                    result.forEach((element) => {
                        element["edit"] = false;
                    });
                    result[0].edit = true
                    _this.commodityForm.commoditySpecifications = result;
                    _this.commoditySpecifications = _this.commodityForm.commoditySpecifications[0];
                    _this.dialogVisible2 = true;

                }, function (err) { console.log(err); });
        },
        //规格增删改---------开始
        handleAdd() {
            let row = {
                edit: true,
                name: '',
                // guidancePrice: null,
                // vip1Price: null,
                // vip2Price: null,
                // membershipPrice: 0,
                ordinaryPrice: null,
                stockNum: null,
                isIntegral: true,
                integral: null,
                rank: 0
            }
            this.commodityForm.commoditySpecifications.push(row)
        },
        editData(row) {
            this.commoditySpecifications = row
            var array = this.commodityForm.commoditySpecifications
            for (let index = 0; index < array.length; index++) {
                if (array[index].id == row.id) {
                    this.editIndex = index
                    array[index].edit = true
                } else {
                    array[index].edit = false
                }

            }
        },
        confirmData(row) {
            row.edit = false;
            // console.log(row.edit);
            // console.log(row.price);
            this.$notify({
                title: "Success",
                message: "编辑成功",
                type: "success",
                duration: 2000,
            });
        },
        handleDelete(row, index) {
            this.$notify({
                title: "Success",
                message: "Delete Successfully",
                type: "success",
                duration: 2000,
            });
            this.commodityForm.commoditySpecifications.splice(index, 1);
        },
        //规格增删改---------结束
        saveSpecifications(formName) {
            this.commodityForm.commoditySpecifications[this.editIndex] = this.commoditySpecifications
            var _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.CommodityDomain.SetSpecifications(_this.commodityForm.commoditySpecifications,
                        function (data) {
                            _this.$message({
                                type: 'success',
                                message: '修改成功!'
                            });
                            _this.dialogVisible2 = false;
                            _this.LoadCommoditys();
                        },
                        function (error) {
                            console.log(error);
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        remove(item) {
            var _this = this;
            this.$confirm('确定删除此条商品信息吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.CommodityDomain.Remove(item.id,
                    function (data) {
                        _this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        _this.LoadCommoditys();
                    },
                    function (error) {
                        console.log(error);
                    });
            }).catch(() => { });

        },
    }
}
</script>

<style scoped>

.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar {
    position: relative;
    height: 73px;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    flex: 1;
    text-align: right;
    margin-right: 15px;
}

.messages {
    margin-top: 13px;
    margin-right: 20px;
}

.messages .el-icon-bell {
    font-size: 26px;
}

/*content*/


.table-top {}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .el-input {
    width: 300px;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #ceebee;
    line-height: 1.8;
}

.img-div /deep/ .el-upload {
    display: none;
}

.isShare-box div .el-input {
    width: 70px;
}

.v-html img {
    height: 300px;
}

._table {
    width: 60%;
    border-collapse: collapse;
    border: 0px;
}

._table thead tr {
    font-size: 13px;
    color: #2e3b45;
    text-align: center;
    background-color: rgba(230, 255, 250, 0.92);
    font-weight: bold;
}

._table td {
    line-height: 30px;
    text-align: center;
    padding: 4px 10px 3px 10px;
    height: 18px;
    border: 1px solid #EBEEF5;
}

._table /deep/ .el-form-item__error {
    top: 88%;
}
</style>
